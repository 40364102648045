<template>
  <div class="allocation-edit">
    <div class="ae-box">
      <div class="ae-filter">
        <div class="ae-border ae-pad">筛选条件</div>
        <div class="ae-body ae-pad">
          <div>机构名</div>
          <a-select @change="selectChange" @clear="clearIns" class="ae-select" v-model:value="nameContains" show-search
            placeholder="输入机构名" allow-clear :default-active-first-option="false" :loading="loading"
            @search="handleSearch">
            <a-select-option v-for="option in options" :key="option.value" :value="option.value" :name="option.value">
              {{ option.label }}
            </a-select-option>
          </a-select>
          <div>申请国家</div>
          <a-select class="ae-select" v-model:value="currCountry" @change="selectChange" placeholder="不限" allow-clear
            show-search :filter-option="filterOption">
            <a-select-option v-for="option in countries" :key="option.value" :name="option.value">{{ option.value }}
            </a-select-option>
          </a-select>
          <div>业务类型</div>
          <a-select class="ae-select" v-model:value="currEntity" @change="selectChange" placeholder="不限" allow-clear
            show-search :filter-option="filterOption">
            <a-select-option v-for="option in entities" :key="option.id" :name="option.name">{{ option.name }}
            </a-select-option>
          </a-select>
          <div>接待城市</div>
          <a-select @change="selectChange" class="ae-select" v-model:value="receptionCityContainsIn" placeholder="不限"
            allow-clear show-search :filter-option="filterOption">
            <a-select-option v-for="option in receptionCitises" :key="option" :name="option">{{ option }}
            </a-select-option>
          </a-select>
          <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
            <a-input-number v-model:value="minPrice" style="width: 80%;" addon-before="低于"
              addon-after="元"></a-input-number>
            <SearchOutlined @click="selectChange" class="search-button" />
          </div>
        </div>
      </div>
      <div class="ae-coustomer">
        <div class="ae-border ae-pad-t flex-between">
          <span>可选机构</span>
          <a-select size="small" style="width: 100px" v-model:value="currSortType" @change="selectChange">
            <a-select-option v-for="option in sortTypes" :key="option.id">{{ option.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ae-body ae-pad">
          <div v-for="(item, index) in institutionsList" @click="selectItem(item)" class="aes-item">
            <span :style="{ color: item.color ? item.color : '' }"
              :class="[item.disabled ? 'aes-disabled' : 'ae-hover']">{{
            item.name }}</span>
            <span :style="{ color: item.color ? item.color : '' }"
              :class="[item.disabled ? 'aes-disabled' : 'ae-hover']">评分:{{ item.systemScore }}</span>
          </div>
        </div>
      </div>
      <div class="ae-selected">
        <div class="ae-border ae-pad flex-between">已选</div>
        <div class="ae-body ae-pad">
          <div v-for="(item, index) in selectList" class="aes-item-selected aes-flex">
            <span>{{ item.name }}</span>
            <close-outlined @click="deleteSelect(item, index)" />
          </div>
        </div>
      </div>
    </div>
    <div class="ae-tips">已分配的机构不能再选择</div>
    <a-button @click="submit" type="primary">分配</a-button>
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { get, post } from "@/utils/http";
import { country, companyEntity, receptionCity } from '@/utils/public';
import { toHandlers, ref } from "vue";
import { debounce } from "lodash";
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "allocationEdit",
  data() {
    return {
      countries: [],
      receptionCitises: [],
      entities: [],
      sourceList: [],
      currCountry: null,
      currEntity: null,
      receptionCityContainsIn: null,
      institutionsList: [],
      selectList: [],
      institutionsId: [],
      checkAll: false,
      indeterminate: false,
      selectCostumers: [],
      // allList: [],
      // num: 0,
      nameContains: null,
      options: [],
      loading: false,

      minPrice: '',
      sortTypes: [
        {
          name: '签单率',
          id: 'signRate'
        },
        {
          name: '新签约',
          id: 'signTime'
        },
      ],
      currSortType: 'signRate'
    }
  },
  watch: {
    institutionsId(newVal, oldVal) {
      this.selectCostumers = [...new Set([...this.selectCostumers, ...newVal])];
    }
  },
  created() {
    this.countries = country;
    this.entities = companyEntity;
    this.receptionCitises = receptionCity;
    this.getDetail();
  },
  methods: {
    clearIns() {
      this.options = []
    },
    handleSearch: debounce(async function (val) {
      if (val) {
        let data = []
        this.loading = true;
        let res = await get("/agency/institutions/listAll", { nameOrContactOrPhoneNumberOrCompanySubjectContains: val });
        if (res.code == 200) {
          let result = res.data
          result.forEach(r => {
            data.push({
              label: r.name,
              value: r.name,
            });
          });
        }
        setTimeout(() => {
          this.options = data
          this.getCustomer();
          this.loading = false;
        }, 1000);
      }

    }, 300),

    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    async getDetail() {
      this.detailLoading = true;
      let ids = this.selectedRows.map(item => item.id);
      try {
        let res = await get("/agency/institutionsCustomer/allocation/get", { id: ids });
        this.sourceList = res.source.list;
        this.getCustomer();
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async getCustomer() {
      try {
        let res = await get("/agency/institutionsCustomer/acquisitionAgency/get2", {
          countryOfApplicationContainsIn: this.currCountry,
          businessTypeContainsIn: this.currEntity,
          nameContains: this.nameContains,
          receptionCityContainsIn: this.receptionCityContainsIn,
          minPrice: this.minPrice,
          sort: this.currSortType
        });
        res.institutionsList.map((item, index) => {
          if (this.sourceList.indexOf(item.id) > -1) {
            item.disabled = true
          };
        })
        this.institutionsList = res.institutionsList;
        this.getSelectColor();
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async submit() {
      this.detailLoading = true;
      if (this.selectList.length == 0) {
        this.$message.error('请选择分配的机构');
        return
      }
      let institutionsIds = this.selectList.map(item => item.id);
      let institutionsNames = this.selectList.map(item => item.name);
      let ids = this.selectedRows.map(item => item.id);
      try {
        let res = await post("/agency/institutionsCustomer/allocation", {
          institutionsId: institutionsIds,
          id: ids,
          institutionsName: institutionsNames
        });
        this.$emit('close');
      } catch (e) {
        this.$message.error(e.message)
      }
    },
    selectChange(e) {
      this.getCustomer();
    },
    selectItem(item) {
      if (item.disabled) {
        return
      };
      let index = this.selectList.findIndex(i => {
        return i.id === item.id
      })
      if (index == -1) {
        this.selectList.push(item)
      };
      this.getSelectColor();
    },

    deleteSelect(item, index) {
      this.selectList.splice(index, 1);
      this.getSelectColor();
    },

    getSelectColor() {
      this.institutionsList.map((item, index) => {
        let i = this.selectList.findIndex(i => {
          return i.id === item.id
        });
        if (i > -1) {
          item.color = '#1677ff'
        } else {
          item.color = null
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.allocation-edit {
  .ae-pad {
    padding: 10px;
  }

  .ae-body {
    height: 400px;
    overflow-y: scroll;
  }

  .ae-box {
    display: flex;
    align-items: center;
  }

  .ae-filter {
    border: 1px solid #eeeeee;
    border-radius: 8px 0 0 8px;
    width: 30%;
    min-width: 130px;
  }

  .ae-coustomer {
    border: 1px solid #eeeeee;
    border-left: none;
    border-radius: 0 8px 8px 0;
    width: 40%;
    min-width: 250px;
  }

  .ae-selected {
    border: 1px solid #eeeeee;
    border-radius: 8px;
    width: 30%;
    margin-left: 10px;
    min-width: 200px;
  }

  .ae-border {
    border-bottom: 1px solid #eeeeee;
  }

  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ae-pad-t {
    padding: 7.5px 10px;
  }

  .ae-select {
    width: 90%;
    margin: 10px 0 30px 0;
  }

  .ae-bottom {
    margin-bottom: 10px;
  }

  .aes-item {
    padding: 4px 8px;
    border-radius: 4px;
    margin-bottom: 6px;
    cursor: pointer;
    height: 20px;
    line-height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .ae-hover {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:nth-child(1) {
        min-width: 60%;
      }

      &:nth-child(2) {
        min-width: 40%;
        text-align: right;
      }
    }

  }

  .aes-item-selected {
    background-color: #eeeeee;
    padding: 4px 8px;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .ae-hover:hover {
    color: #1677ff;
  }

  .aes-disabled {
    color: #999999;
    cursor: not-allowed;
  }

  .aes-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: none;
    /*可以设置轨道颜色*/
    padding-right: 2px;
  }

  /*滚动条*/
  ::-webkit-scrollbar-thumb {
    background: rgba(142, 142, 142, 0.4);
    border-radius: 6px;
  }

  .ae-tips {
    font-size: 12px;
    color: #999999;
    padding: 12px 0;
  }

  .search-button {
    display: inline-block;
    color: #999999;
    border-radius: 50%;
    font-size: 18px;
    padding: 6px;
    cursor: pointer;
  }
}
</style>
